<template>
    <div>
        <div class="container-fluid" v-if="loading || course == null">
            Loading...
        </div>

        <template v-else>
			<ui-menubar v-if="saving">
				<ui-menubar-item label="Saving..." icon-left="fas fa-sync fa-spin"/>
			</ui-menubar>
			<ui-menubar v-else sticky>
                <ui-menubar-item primary label="Save Entry" icon-left="fas fa-save" @click="save" />
                <ui-menubar-item :label="returnButtonText" icon-left="fas fa-arrow-circle-left" :to="returnUrl" />
			</ui-menubar>

            <div class="container-fluid">
				<div class="title">Lottery Entry Details</div>

				<div class="booking-form-row">
					<div class="col-md-3">
						<lottery-entry-details ref="details"/>

						<!-- INTERFACE NOTES -->
						<div v-for="note in interfaceNotes" :key="note.id" v-html="note.note_content" />

						<template v-if="$breakpoints.smAndDown">
							<ui-button block color="primary" :loading="saving" @click="save">Save Entry</ui-button>
							<br>
							<ui-button block color="text" :disabled="saving" :to="returnUrl">Back to Teesheet</ui-button>
						</template>
					</div>
					<div class="col">
						<member-search-bar :course-id="course.id" @selected="addUserPlayer"/>

						<ui-panel>
							<!-- <ui-button color="primary" @click="showFillForm = true">Add Fill</ui-button> -->
							<ui-menubar>
								<ui-menubar-item label="Add Fill" icon-left="fas fa-plus" @click="showFillForm = true"/>
								<ui-menubar-item label="Add Guest" icon-left="fas fa-plus" @click="showGuestForm = true"/>
							</ui-menubar>
						</ui-panel>

						<booking-members is-lottery />
					</div>
				</div>
            </div>

			<add-fill-form :show.sync="showFillForm" />
			<add-guest-form :show.sync="showGuestForm" />
        </template>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';

import MemberSearchBar from './MemberSearchBar.vue';
import LotteryEntryDetails from './LotteryEntryDetails.vue';
import BookingMembers from './BookingMembers.vue';
import AddFillForm from './AddFillForm.vue';
import AddGuestForm from './AddGuestForm.vue';

export default {
	components: { MemberSearchBar, LotteryEntryDetails, BookingMembers, AddFillForm, AddGuestForm },

	props: {
		id: { type: String, required: true },
		blockId: { type: [Number, String], required: true },
	},

	data()
	{
		return {
			loading: true,
			saving: false,
			showFillForm: false,
			showGuestForm: false,
		}
	},

	computed: {
		...mapGetters('teesheet', ['teetimeBookers']),

		...mapState({
			course: state => state.teesheet.course,
			courseParams: state => state.teesheet.courseParams,
			date: state => state.teesheet.date,
			teetimes: state => state.teesheet.teetimes,
			block: state => state.teesheet.reservationBlock,
			players: state => state.teesheet.teetimePlayers,
			currentUser: state => state.shell.currentUser,
			userClass: state => state.shell.userClass,
			interfaceNotes: state => state.shell.interfaceNotes.lottery_entry || [],
			reservation: state => state.teesheet.reservation,
		}),

		returnButtonText()
		{
			var returnTo = this.$route.query.returnTo;

			if (returnTo == 'entries')
				return 'Back To Entries';
			else
				return 'Back To Teesheet'
		},

		returnUrl()
		{
			var returnTo = this.$route.query.returnTo;

			if (returnTo == 'entries')
				return `/lottery_entries/${this.block.id}`;
			else
				return `/teesheet/${this.course.url_slug}/${this.date}`;
		}
	},

	methods: {
		...mapActions('buddies', ['fetchBuddyList']),
		...mapActions('guests', ['fetchGuestList']),
		...mapMutations('teesheet', ['addTeetimePlayer', 'addTeetimeFill', 'setReservationDetails', 'clearReservationPlayers']),
		...mapActions('teesheet', ['addPlayerToReservation', 'fetchReservationBlock', 'fetchLotteryEntry', 'submitLotteryEntry']),

		addUserPlayer(user)
		{
			if (user.user_type == 'fill')
				this.addTeetimeFill(user);
			else
				this.addPlayerToReservation(user);
		},

		async loadPreviousEntries()
		{
			this.loading = false;

			try
			{
				//await this.fetchReservationPlayers();
			}
			catch(err)
			{
				this.$router.push(this.returnUrl);
			}
			finally
			{
				this.loading = false;
			}
		},

		async init()
		{
			this.loading = true;

			try
			{
				// load block details
				await this.fetchReservationBlock(this.blockId);
				
				// load buddy list
				await this.fetchBuddyList();
				await this.fetchGuestList();

				// load previously submitted entries
				//await this.loadPreviousEntries();

				// set default reservation details if creating a new entry
				if (this.id == 'new')
				{
					var user = this.currentUser;

					this.clearReservationPlayers();

					this.setReservationDetails({
						id: 0,
						is_lottery_entry: true,
						course_id: this.course.id,
						//user_id: this.currentUser.id,
						only_play_selected: true,
						block_id: this.block.id,
						earliest_time: this.block.ranges[0].start_time,
						latest_time: this.block.ranges[this.block.ranges.length-1].end_time,
							desired_time: "",
						// desired_time: this.block.ranges[0].start_time,
						only_play_selected: true,
					});


					this.addPlayerToReservation({
						id: user.id,
						user_type: 'member',
						primary_email: user.primary_email,
						class_name: this.userClass.name,
						first_name: user.first_name,
						last_name: user.last_name,
						bag_number: user.bag_number,
						member_number: user.member_number,
						cart_id: user.pref_cart_id,
						is_paid: false,
						checked_in: false,
						email_sent: false,
						booked_by: user.id,
						photo_url: user.photo_url
					});
				}
				// otherwise pull entry info from server
				else
				{
					await this.fetchLotteryEntry({ id: this.id });
				}
			}
			finally
			{
				this.loading = false;
			}
		},

		async checkWindowSize()
		{
			var earliest = moment(this.reservation.earliest_time, 'HH:mm');
			var latest = moment(this.reservation.latest_time, 'HH:mm');
			var diff = Math.abs(earliest.unix() - latest.unix()) / 60;

			if (diff <= this.courseParams.min_lottery_window)
			{
				var msg = 'Your earliest/latest time window is too small! The smaller your entry window is the less chance you have of getting ANY time, are you sure you want to continue?';
				return await alerts.confirm(msg, 'Window Too Small', 'question');
			}
			else
			{
				return true;
			}
		},

		async save()
		{
			// make sure details are valid
			if (!this.$refs.details.validate())
				return;

			// make sure at least 1 player is added to the entry
			if (this.players.ids.length == 0)
			{
				alerts.show('No member players added to entry', 'Error', 'error');
				return;
			}

			// window size check
			if (!await this.checkWindowSize())
				return;

			this.saving = true;

			try
			{
				// submit reservation data to server
				await this.submitLotteryEntry();

				// redirect back to teesheet
				this.$router.push(this.returnUrl);
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	async mounted()
	{
		this.init();
	}
}
</script>