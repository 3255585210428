<template>
	<tr>
		<td class="time-cell" :style="timeCellStyle">
			<div class="teetime-btn-cell">

				<!-- display a link to a booking form if the row is not full and in the future -->
				<template v-if="canBook">
					<button class="teetime-btn primary-btn" @click.prevent="gotoBookingForm">
						<div v-if="row.block && row.block.start_type == 'special'">Hole: {{ row.hole }}</div>
						{{ row.squeezed ? 'S -' : '' }} {{ row.time | to12Hour }}
						<div v-if="isPublic && row.price" class="pt-1">{{ parseFloat(row.price.price_18holes) | currency }}</div>
					</button>
				</template>

				<!-- otherwise just display the time -->
				<template v-else>
					<button class="teetime-btn primary-btn" disabled>
						<div v-if="row.block && row.block.start_type == 'special'">Hole: {{ row.hole }}</div>
						{{ row.squeezed ? 'S -' : '' }} {{ row.time | to12Hour }}
					</button>
				</template>
			</div>
		</td>

		<!-- STACK PLAYER LIST VERTICALLY -->
		<template v-if="stackPlayers">
			<td>
				<ul v-if="useRow.length > 0" class="teetime-player-list">
					<li v-for="(p, index) in teetimeRow" :key="index">
						<div class="teetime-cell-contents">

							<!-- display removal icon if valid -->
							<button v-if="p && !isPast && canRemove(p)" class="cancel-btn" @click.prevent="removePlayer(p)"><i class="fas fa-times-circle"/></button>
							
							<!-- display player name -->
							<template v-if="p != null && p.user_type == 'member' && !p.hidden">
								<router-link :style="cellStyle(p)" :to="`/teesheet/${course.url_slug}/${date}/user/${p.player_id}`">{{ cellText(p) }}</router-link>
							</template>

							<template v-else>
								<span :style="cellStyle(p)">{{ cellText(p) }}</span>
							</template>

						</div>
						<!-- display member class / cart pref if configured -->
							<template v-if="p != null">
									<span  :style="cellStyle(p)">{{ cellStatus(p) }}</span>
							</template>
					</li>
				</ul>
			</td>
		</template>

		<!-- DISPLAY PLAYERS AS TABLE ROW -->
		<template v-else>
			<td v-for="(p, index) in teetimeRow" :key="index" :style="playerCellStyle">
				<div class="teetime-cell-contents">
					<!-- display removal icon if valid -->
					<button v-if="p && !isPast && canRemove(p)" class="cancel-btn" @click.prevent="removePlayer(p)"><i class="fas fa-times-circle"/></button>
					
					<!-- display player name -->
					<template v-if="p != null && p.user_type == 'member' && !p.hidden">
						<router-link :style="cellStyle(p)" :to="`/teesheet/${course.url_slug}/${date}/user/${p.player_id}`">{{ cellText(p) }}</router-link>
					</template>

					<template v-else>
						<span :style="cellStyle(p)">{{ cellText(p) }}</span>
					</template>
		
				</div>
							<!-- display member class / cart pref if configured -->
							<template v-if="p != null">
									<span  :style="cellStyle(p)">{{ cellStatus(p) }}</span>
							</template>
			</td>
		</template>
	</tr>
</template>

<script>
import moment from 'moment';
import swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';

import filters from 'igolf/shared/filters';
import alerts from 'igolf/shared/alerts';

const today = moment();

export default {
	mixins: [filters],

	inject: ['$setLoadingCover'],
	
	props: {
		row: { type: Object, required: true },
		players: { type: Array, default: null },
		stackPlayers: { type: Boolean, default: false },
	},

	data()
	{
		return {
			checkedIn: false
		}
	},
		
	computed: {
		...mapState({
			course: state => state.teesheet.course,
			courseParams: state => state.teesheet.courseParams,
			date: state => state.teesheet.date,
			perRow: state => state.teesheet.teetimes.perRow,
			currentUser: state => state.shell.currentUser,
			userClass: state => state.shell.userClass,
			carts: state=> state.shell.carts,
		}),

		useRow()
		{
			return this.players ? this.players : this.row.players;
		},

		isPublic()
		{
			return !this.currentUser || this.currentUser.is_public;
		},

		bookingUrl()
		{
			if (!this.currentUser || this.currentUser.is_public)
			{
				var bookingUrl = `/teesheet/${this.course.url_slug}/${this.date}/public_booking/${this.row.id}`;
				var redirect = `/login?redirect=${encodeURIComponent(`/users${bookingUrl}`)}`;
				
				return this.currentUser ? bookingUrl : redirect;
			}
			else
			{
				return `/teesheet/${this.course.url_slug}/${this.date}/booking/${this.row.id}`;
			}
		},

		timeCellStyle()
		{
			var styles = {};

			if (this.row.block != null)
				styles.borderLeftColor = this.row.block.teesheet_bg_color;

			return styles;
		},

		playerCellStyle()
		{
			return {};
		},

		teetimeRow()
		{
			// get players in this row
			var row = this.useRow.slice();

			// generate empty spaces in row
			for(var i=0; i<this.perRow - this.useRow.length; i++)
				row.push(null);


			return row;
		},

		isPast()
		{
			var rowDate = moment(`${this.date} ${this.row.time}`, 'YYYY-MM-DD HH:mm');
			return rowDate.isSameOrBefore(today);
		},

		canBook()
		{
			var maxPerRow;
			var rowDate = moment(`${this.date} ${this.row.time}`, 'YYYY-MM-DD HH:mm');
			var bookedByUser = false;

			if (this.row.block)
				maxPerRow = this.row.block.max_players_per_teetime;
			else
				maxPerRow = this.courseParams.default_players_per_row;

			// return false if row belongs to a locked block
			if (this.row.block && this.row.block.is_locked)
				return false;

			// look for players that were booked by the current user
			if (this.currentUser)
			{
				for(var p of this.row.players)
				{
					if (p.booked_by == this.currentUser.id)
					{
						bookedByUser = true;
						break;
					}
				}
			}

			return (bookedByUser || this.row.players.length < maxPerRow) && rowDate.isAfter(today);
		}
	},

	methods: {
		gotoBookingForm()
		{
			if (this.currentUser)
				this.$router.push(this.bookingUrl);
			else
				document.location = this.bookingUrl;
		},

		cellStyle(p)
		{
			return (this.course.show_class_colors && p != null && p.teesheet_color) ? { color: p.teesheet_color } : {};
		},

		cellText(p)
		{
			if (p == null)
				return '';
			else if (p.user_type == 'member' || p.user_type == 'guest')
				return p.player_name;
			else if (p.user_type == 'fill')
				return p.fill_desc;
			else
				return `UNKNOWN TYPE: ${p.user_type}`;
		},

		canRemove(p)
		{
			// users can remove players they booked
			if (this.currentUser && p.booked_by == this.currentUser.id)
				return true;

			// users can remove themselves
			if (p.player_type == 'user' && (this.currentUser && p.player_id == this.currentUser.id))
				return true;

			return false;
		},
		cellStatus(p) {
			if (p != null) {
				var statuses = [];
				var cartId = p.cart_id || p.pref_cart_id;

				if ((p.user_type == 'member' || p.user_type == 'guest') && this.userClass && this.userClass.id == p.user_class_id && this.courseParams.show_member_class ) 
					statuses.push('Class: ' + this.userClass.name);

				if (this.courseParams.show_cart_prefs  && cartId && this.carts[cartId])
					statuses.push('Cart: ' + this.carts[cartId].abbrev);

				return statuses.length > 0 ? `(${statuses.join(', ')})` : '';
			}
			else {
				return null;
			}
		},
		async removePlayer(p)
		{
			var msg, id, isBookingUser, isCurrentUser, cancelBooking = false;

			id = p.id;
			isCurrentUser = p.player_type == 'user' && p.player_id == this.currentUser.id;
			isBookingUser = p.player_type == 'user' && p.player_id == this.currentUser.id && p.booked_by == this.currentUser.id;

			// determine message to prompt user with
			if (isCurrentUser)
				msg = 'Are you sure you want to remove yourself from this teetime? (All guests assigned to you will be removed as well)';
			else
				msg = 'Are you sure you want to remove this player? (All guests assigned to this player will be removed as well)';

			// confirm that user wants to remove this user
			if (!await alerts.confirm(msg, 'Remove Player', 'question'))
				return;

			// if the player being removed warn the user that the entire booking will be cancelled
			if (isBookingUser)
			{
				var result = await swal.fire({
					title: 'Cancel Group?',
					text: 'Do you want to cancel your entire group?',
					icon: 'question',
					showCancelButton: true,
					showCloseButton: true,
					//focusCancel: true,
					cancelButtonText: 'Just remove myself',
					cancelButtonColor: '#3085d6',
					confirmButtonText: 'Cancel group',
					confirmButtonColor: 'red',
					reverseButtons: true,
				});

				if (result.value)
					cancelBooking = true;
				else if (result.dismiss == 'close')
					return;
			}

			this.$setLoadingCover(true);

			try
			{
				await this.$api.post('/reservations/remove_player', { id, cancel_booking: cancelBooking });
				location.reload();
			}
			finally
			{
				this.$setLoadingCover(false);
			}
		}
	}
}
</script>