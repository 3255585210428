<template>
    <div>
        <div class="container-fluid" v-if="loading || course == null">
            Loading...
        </div>

        <template v-else>
			<ui-menubar v-if="saving" frontend>
				<ui-menubar-item label="Saving..." icon-left="fas fa-sync fa-spin"/>
			</ui-menubar>
			<ui-menubar v-else frontend>
                <ui-menubar-item primary-dark label="Save Booking" icon-left="fas fa-save" :disabled="lock && lock.locked" @click="save" />
                <ui-menubar-item label="Back To Teesheet" icon-left="fas fa-arrow-circle-left" :to="returnUrl" />
			</ui-menubar>

            <div class="container-fluid">
				<div class="title">Reservation Details</div>

				<!-- MAIN BOOKING FORM AREA -->
				<div class="booking-form-row">
					<div class="col-md-3">
						<!-- BOOKING DETAILS -->
						<booking-details/>

						<!-- INTERFACE NOTES -->
						<div v-for="note in interfaceNotes" :key="note.id" v-html="note.note_content" />

						<template v-if="$breakpoints.smAndDown">
							<ui-button block color="primary" :loading="saving" @click="save">Save Booking</ui-button>
							<br>
							<ui-button block color="text" :disabled="saving" :to="returnUrl">Back to Teesheet</ui-button>
						</template>

					</div>
					<div class="col">
						<!-- DISPLAY LOCK INFO -->
						<booking-lock-countdown v-if="lock" />

						<!-- MEMBER SEARCH BAR -->
						<member-search-bar :course-id="course.id" @selected="addUserPlayer"/>

						<!-- ADD GUEST/FILL BUTTONS -->
						<ui-panel>
							<!-- <ui-button color="primary" @click="showFillForm = true">Add Fill</ui-button> -->
							<ui-menubar>
								<!-- <ui-menubar-item label="Add Fill" icon-left="fas fa-plus" @click="showFillForm = true"/> -->
								<ui-menubar-item label="Add Guest" icon-left="fas fa-plus" @click="showGuestForm = true"/>
							</ui-menubar>
						</ui-panel>

						<!-- BOOKING MEMBER LIST -->
						<booking-members/>

					</div>
				</div>
            </div>

			<!-- ADD FILL/GUEST POPUPS -->
			<!-- <add-fill-form :show.sync="showFillForm" /> -->
			<add-guest-form :show.sync="showGuestForm" />
        </template>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';

import MemberSearchBar from './MemberSearchBar.vue';
import BookingDetails from './BookingDetails.vue';
import BookingMembers from './BookingMembers.vue';
import BookingLockCountdown from './BookingLockCountdown.vue';
import AddFillForm from './AddFillForm.vue';
import AddGuestForm from './AddGuestForm.vue';

export default {
	components: { MemberSearchBar, BookingDetails, BookingMembers, BookingLockCountdown, AddFillForm, AddGuestForm },

	props: {
		id: { type: String, required: true },
	},

	data()
	{
		return {
			loading: false,
			saving: false,
			showFillForm: false,
			showGuestForm: false,
		}
	},

	computed: {
		...mapGetters('teesheet', ['teetimeBookers']),

		...mapState({
			course: state => state.teesheet.course,
			date: state => state.teesheet.date,
			teetimes: state => state.teesheet.teetimes,
			lock: state => state.teesheet.teetimeLock,
			reservation: state => state.teesheet.reservation,
			interfaceNotes: state => state.shell.interfaceNotes.teetime || [],
		}),

		returnUrl()
		{
			return `/teesheet/${this.course.url_slug}/${this.date}`;
		},

		memberList()
		{

		}
	},

	methods: {
		...mapActions('buddies', ['fetchBuddyList']),
		...mapActions('guests', ['fetchGuestList']),
		...mapMutations('teesheet', ['addTeetimePlayer', 'addTeetimeFill', 'setReservationBlock', 'setReservationDetails', 'updateReservationDetails', 'clearReservationPlayers']),
		...mapActions('teesheet', [
			'addPlayerToReservation', 'submitReservation', 'fetchReservationPlayers', 'fetchReservationBlock', 
			'fetchTeetimeLock',
		]),

		addUserPlayer(user)
		{
			try
			{
				if (user.user_type == 'fill')
					this.addTeetimeFill(user);
				else
					this.addPlayerToReservation(user);
			}
			catch(err)
			{
				if (err.isActionError)
					alerts.show(err.message, 'Error', 'error');
				else
					throw err;
			}
		},

		async loadPreviousEntries()
		{
			this.loading = true;

			try
			{
				// look for existing booked players
				await this.fetchReservationPlayers();
			}
			/*catch(err)
			{
				console.error(err);
				this.$router.push(this.returnUrl);
			}*/
			finally
			{
				this.loading = false;
			}
		},

		async save()
		{
			this.saving = true;

			try
			{
				// submit reservation data to server
				await this.submitReservation();

				// redirect back to teesheet
				this.$router.push(this.returnUrl);
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	async mounted()
	{
		this.setReservationBlock(null);

		if (this.teetimes.rows[this.id])
		{
			var id, row;

			id = this.id;
			row = this.teetimes.rows[id];

			// if row is blocked then punt back to the teesheet
			if (row.block && row.block.is_locked)
			{
				this.$router.push(this.returnUrl);
				alerts.show('This teetime is locked', 'Locked', 'error');
				return;
			}


			// set reservation details
			this.setReservationDetails({
				courseId: this.course.id,
				teetimeId: this.id,
				dateOf: this.date,
				numHoles: 18,
				timeOf: row.time,
				hole: row.hole,
				ignoreRestrictions: false,
				squeeze: false,
				squeezeId: 0,
				notes: row.players.length? row.players[0].notes : '',
			});

			// get lock info
			await this.fetchTeetimeLock(row.time);

			// load block details
			if (row.block)
				await this.fetchReservationBlock(row.block.id);
		
			// load buddy list
			await this.fetchBuddyList();
			await this.fetchGuestList();

			// load previously submitted entries
			await this.loadPreviousEntries();
		}

	},
}
</script>