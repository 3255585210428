
import moment from 'moment';

export default {
	course: {},
	courseParams: {},
	date: moment().format('YYYY-MM-DD'),
	loaded: false,
	header: {
		weather: '',
		notes: '',
	},

	teesheetNotes: null,
	segments: null,
	teetimes: null,
	schedule: null,

	// booking form state
	reservation: {},
	reservationBlock: null,
	teetimeLock: null,
	teetimesLoading: false,

	teetimePlayers: {
		ids: [],
		players: {},
	},

	teetimeFills: {
		ids: [],
		fills: {},
	},

	removedTeetimes: [],

	// lottery entry state
	entriesLoading: false,
	userLotteriesEntered: {},
	userLotteryGroups: {},
	viewingLotteryEntries: 0,

	lotteryEntries: {
		ids: [],
		entries: {},
		block: {},
	},

	publicPlayerGuests: {
		nextId: 1,
		ids: [],
		guests: {},
	}
};