<template>
	<ui-panel>
		<!-- USER AVATAR, NAME, CLASS AND MEMBER# -->
		<ui-panel-title>
			<!-- show member name if this is a regular member/guest user -->
			<div v-if="type == 'user'" class="member-card-title">
				<div class="card-title-avatar">
					<ui-avatar :src="user.photo_url || '/images/avatar-placeholder.gif'" :size="48"/>
				</div>
				<div class="card-title-details">
					<div class="card-title-name">{{ user.last_name }}, {{ user.first_name }}</div>
					<div class="card-title-info">{{ cardTitle }}</div>
				</div>

				<div class="card-title-buttons">
					<!-- has delete override? -->
					<ui-button v-if="$listeners.delete" color="danger" aria-label="Remove" @click="$emit('delete', user.id)"><i class="fas fa-trash"></i></ui-button>

					<!-- otherwise use direct deletion -->
					<ui-button v-else color="danger" aria-label="Remove" @click="removePlayer"><i class="fas fa-trash"></i></ui-button>
				</div>
			</div>

			<!-- otherwise display fill info -->
			<div v-else-if="type == 'fill'" class="member-card-title">
				<div class="card-title-avatar">
					<ui-avatar src="/images/avatar-placeholder.gif" :size="48"/>
				</div>
				<div class="card-title-details">
					<div class="card-title-name">{{ user.fill_desc }}</div>
				</div>

				<div class="card-title-buttons">
					<ui-button color="danger" aria-label="Remove" @click="removeTeetimeFill(user.id)"><i class="fas fa-trash"></i></ui-button>
				</div>
			</div>
		</ui-panel-title>

		<!-- USER DETAILS -->
		<ui-panel-body>
			<ui-form ref="form">
				<div class="row">
					<div class="col-md-6">
						<ui-select-field
							compact
							v-model="cart"
							label="Cart"
							empty-text="No Cart"
							:empty-value="0"
							:items="cartOptions"
							item-text="abbrev"
							item-value="id">
						</ui-select-field>
					</div>

					<div v-if="!reservation.is_lottery_entry" class="col-md-6">
						<ui-select-field
							compact
							v-model="bookedBy"
							label="Booked By"
							:items="bookedByOptions">
						</ui-select-field>
					</div>
				</div>
			</ui-form>
		</ui-panel-body>
	</ui-panel>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';

export default {
	props: { 
		user: { type: Object, required: true },
		type: { type: String, required: true },
	},

	computed: {
		...mapGetters('teesheet', ['teetimePlayers']),
		...mapGetters('shell', ['cartList']),
		...mapState({
			course: state => state.teesheet.course,
			currentUser: state => state.shell.currentUser,
			reservation: state => state.teesheet.reservation,
		}),

		cartOptions()
		{
			return this.cartList.filter(c => c.courses.indexOf(this.course.id) >= 0);
		},

		bookedBy: {
			get()
			{
				return this.user.booked_by;
			},

			set(val)
			{
				this.updateField('booked_by', val);
			}
		},

		bookedByOptions()
		{
			return this.teetimePlayers.filter(p => p.user_type == 'member').map(p => ({
				text: `${p.last_name}, ${p.first_name}`,
				value: p.id,
			}))
		},

		cardTitle()
		{
			if (this.user.user_type == 'member')
			{
				var title = this.user.class_name ? `${this.user.class_name}, `:'';
				
				if (this.user.member_number)
					title += `Member #${this.user.member_number}`;
				

				return title;
			}
			else if (this.user.user_type == 'guest')
			{
				return 'Guest';
			}
			else
			{
				return '';
			}
		},

		cart: {
			get() { return this.user.cart_id; },
			set(val) { this.updateField('cart_id', val); }
		},

	},

	methods: {
		...mapMutations('teesheet', ['removeTeetimePlayer', 'removeTeetimeFill', 'updateTeetimePlayer', 'updateTeetimeFill']),

		updateField(field, val)
		{
			if (this.type == 'user')
				this.updateTeetimePlayer({ ...this.user, [field]: val });
			else
				this.updateTeetimeFill({ ...this.user, [field]: val });
		},

		async removePlayer()
		{
			if (this.currentUser.id == this.user.id)
			{
				alerts.show('You cannot remove yourself', 'Error', 'error');
				return;
			}

			if (!await alerts.confirm('Are you sure you want to remove this player?', 'Remove Player'))
				return;

			this.removeTeetimePlayer(this.user.id);
		}
	}
}
</script>