<template>
	<ui-panel>
		<ui-panel-title><div class="subtitle">Reservation Details</div></ui-panel-title>
		<ui-panel-body>
			<ui-form ref="form">
				<template v-if="isPublic && teetime.price">
					<ui-text-box-field
						readonly
						label="Price"
						:value="groupPrice"
						:hint="`Price per player: ${teetimePrice}`"
					/>
				</template>

				<ui-text-box-field
					readonly
					label="Date"
					:value="reservation.dateOf">
				</ui-text-box-field>

				<ui-select-field
					label="Number of holes"
					:items="numHoleOptions"
					:value="reservation.numHoles"
					@input="updateField('numHoles', $event)">
				</ui-select-field>

				<!--
				<ui-select-field
					disabled
					label="Time"
					:items="timeOptions"
					:value="reservation.timeOf"
					@input="setTime($event)">
				</ui-select-field>
				-->

				<template v-if="editingTime">
					<ui-select-field
						label="Time"
						:items="timeOptions"
						v-model="currentTime"
					/>

					<div class="row">
						<div class="col">
							<ui-form-field>
								<ui-button block color="primary-bordered" icon="fas fa-check" :loading="changingTime" @click="updateTime">Ok</ui-button>
							</ui-form-field>
						</div>
						<div class="col">
							<ui-form-field>
								<ui-button block color="danger-bordered" icon="fas fa-times" :disabled="changingTime" @click="editingTime = false">Cancel</ui-button>
							</ui-form-field>
						</div>
					</div>
				</template>

				<template v-else>
					<ui-form-field label="Time">
						<ui-button block color="primary-bordered" icon="fas fa-clock" @click="editTime">{{ reservation.timeOf | to12Hour }}</ui-button>
					</ui-form-field>
				</template>


				<ui-select-field
					label="Starting on"
					:items="startingOnOptions"
					:value="reservation.hole"
					@input="updateField('hole', $event)">
				</ui-select-field>

				<br>
				<ui-text-area-field
					v-model="reservation.notes"
					label="Note"
					:value="reservation.notes"
					@input="updateField('notes', $event)"
					>
				</ui-text-area-field>

			</ui-form>
		</ui-panel-body>
	</ui-panel>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import filters from 'igolf/shared/filters';
import alerts from 'igolf/shared/alerts';

const now = moment();

export default {
	mixins: [filters],

	data()
	{
		return {
			editingTime: false,
			changingTime: false,
			numHoleOptions: [
				{ text: '18 Holes', value: 18 },
			],
		}
	},

	computed: {
		...mapGetters('teesheet', ['publicGuestList']),
		...mapState({
			course: state => state.teesheet.course,
			reservation: state => state.teesheet.reservation,
			block: state => state.teesheet.reservationBlock,
			teetimes: state => state.teesheet.teetimes,
			date: state => state.teesheet.date,
			currentUser: state => state.shell.currentUser,
			carts: state => state.shell.carts,
		}),

		isPublic()
		{
			return !this.currentUser || this.currentUser.is_public;
		},

		teetime()
		{
			var id = `${this.reservation.timeOf}_${this.reservation.hole}`;
			return this.teetimes.rows[id];
		},

		pricePerHole()
		{
			if (this.block && this.block.num_holes == 9)
				return parseFloat(this.teetime.price.price_9holes);
			else
				return parseFloat(this.teetime.price.price_18holes);
		},

		teetimePrice()
		{
			var price = this.pricePerHole;
			return filters.filters.currency(price);
		},

		groupPrice()
		{
			// calculate price for group
			var qty = this.reservation.publicGuests + 1;
			var price = this.pricePerHole * qty;

			// add on carts
			if (this.carts[this.reservation.cartPref])
				price += parseFloat(this.carts[this.reservation.cartPref].price);

			for(var guest of this.publicGuestList)
				if (this.carts[guest.cart_id])
					price += parseFloat(this.carts[guest.cart_id].price);

			return filters.filters.currency(price);
		},

		timeOptions()
		{
			var times = [];
			var options = [];
			
			// go over all teetime entry ids
			for(var t of this.teetimes.times)
			{
				// get row mapped to this id
				let row = this.teetimes.rows[t];

				// skip if time is in the past
				let dateOf = moment(`${this.date} ${row.time}`, 'YYYY-MM-DD HH:mm');
				if (dateOf.isSameOrBefore(now))
					continue;

				// only return distinct time options
				if (times.indexOf(row.time) == -1)
				{
					times.push(row.time);
					options.push({
						text: moment(this.teetimes.rows[t].time, 'HH:mm').format('h:mma'),
						value: this.teetimes.rows[t].time,
					})
				}
			}

			return options;
		},

		startingOnOptions()
		{
			var options = [];

			// look for teetime entries with the same time and use their starting hole settings
			for(var id in this.teetimes.rows)
			{
				let row = this.teetimes.rows[id];

				// skip squeezed rows
				if (row.squeezed)
					continue;

				// don't care if this time doesn't match the currently selected starting time
				if (row.time != this.reservation.timeOf)
					continue;

				// add starting hole option
				options.push({
					text: `Hole ${row.hole}`,
					value: row.hole,
				});
			}

			return options;
		}
	},

	methods: {
		...mapMutations('teesheet', ['updateReservationDetails']),
		...mapActions('teesheet', ['fetchTeetimeLock']),

		updateField(field, val)
		{
			this.updateReservationDetails({ [field]: val });
		},


		editTime()
		{
			this.editingTime = true;
			this.currentTime = this.reservation.timeOf;
		},

		async updateTime()
		{
			var lockResult;
			var lockUrl = `/teesheet/${this.course.url_slug}/${this.date}/${this.currentTime}/lock`

			this.changingTime = true;

			try
			{
				// make sure the user wants to do this
				if (!await alerts.confirm('About to change time of booking, are you sure?', 'Change Time'))
					return;

				// look for a lock on the current time
				lockResult = await this.$api.get(lockUrl, { params: { check_only: true } });

				// if the time was already locked then prompt the admin to cancel it
				if (lockResult.data.locked)
				{
					var msg = 'There is already a lock on this time. Please select another time.';
					await alerts.show(msg, 'Teetime Locked', 'warning');
					return;
				}

				// acquire lock for new time
				await this.fetchTeetimeLock(this.currentTime);

				// change reservation time
				this.setTime(this.currentTime);

				// clear editing interface
				this.editingTime = false;
			}
			finally
			{
				this.changingTime = false;
			}
		},


		setTime(val)
		{
			// update time in state
			this.updateField('timeOf', val);

			// reset starting hole 
			this.$nextTick(() => {
				this.updateField('hole', this.startingOnOptions[0].value);
			})
		}
	},

	created()
	{
		if (this.block && this.block.num_holes == 9)
			this.numHoleOptions = [ { text: '9 holes', value: 9 } ];
		else 
			this.numHoleOptions = [ { text: '18 holes', value: 18 } ];
	}

}
</script>