
import api from 'igolf/users/api';

import alerts from 'igolf/shared/alerts';

export default {
	async fetchTeesheetDetails({ state, commit })
	{
		commit('setTeetimesLoading', true);

		try
		{
			var result = await api.get(`/teesheet/${state.course.url_slug}/${state.date}`);
			commit('setTeesheetDetails', result.data);
			commit('setTeetimes', result.data);
		}
		finally
		{
			commit('setTeetimesLoading', false);
		}
	},

	// add player to booking 
	async addPlayerToReservation({ state, commit, getters }, user)
	{
		var bookedBy;

		// raise error if already added
		if (state.teetimePlayers.ids.includes(user.id))
		{
			// var err = new Error('User has already been added');
			// err.isActionError = true;
			// throw err;
			alerts.show('User has already been added', 'Error', 'error');
			return;	
		}
		if (state.reservationBlock?.id && (state.reservation.desired_time||state.reservation.timeOf)){
		//need to check user restiction here
		let params = {
			user_id: user.id,
			time: state.reservation.desired_time || state.reservation.timeOf,// desired time,			
		};

		var result =  await api.post(`/teetimes/${state.course.url_slug}/${state.reservationBlock.id}/check_user_restrictions`, params)			
		if (result.data.is_user_restricted) {
			alerts.show(result.data.message, 'Error', 'error');
			return;			
		}
	}
			

		// use first possible booking member
		if (getters.teetimeBookers.length > 0)
			bookedBy = getters.teetimeBookers[0].id;
		// no possible bookers? if player being added is a member use their id instead
		else if (user.user_type == 'member')
			bookedBy = user.id;

		// select cart pref if specified
		user.cart_id = user.pref_cart_id || user.cart_id;

		// commit member to state
		commit('addTeetimePlayer', { ...user, booked_by: bookedBy });
	},

	// get teetime lock info
	async fetchTeetimeLock({ state, commit }, time)
	{
		var result = await api.get(`/teesheet/${state.course.url_slug}/${state.date}/${time}/lock`);
		
		commit('setTeetimeLock', result.data);

		return result.data;
	},

	// get players in teetime
	async fetchReservationPlayers({ state, rootState, commit })
	{
		var params = {
			course_id: state.reservation.courseId,
			block_id: state.reservation.blockId || null,
			date: state.reservation.dateOf,
			time: state.reservation.timeOf,
			hole: state.reservation.hole,
			squeeze_id: state.reservation.squeeze ? state.reservation.squeezeId : null,
		};

		// get current player entries from server
		var result = await api.get('/reservations/current', { params });

		// clear current reservation players
		commit('clearReservationPlayers');

		// if there are players/fills already present..
		if (result.data.entries.length > 0)
		{
			let players = [], fills = [];

			// delineate into users/fills
			for(let e of result.data.entries)
			{
				// add user player
				if (e.user_type == 'member' || e.user_type == 'guest')
				{
					players.push({
						teetime_id: e.id,
						id: e.player_id,
						user_type: e.user_type,
						primary_email: e.player_email,
						class_name: e.player_class_name,
						first_name: e.player_first_name,
						last_name: e.player_last_name,
						bag_number: e.bag_number,
						member_number: e.member_number,
						cart_id: e.cart_id,
						is_paid: e.is_paid,
						checked_in: e.checked_in,
						email_sent: e.email_sent,
						booked_by: e.booked_by,
						photo_url: e.photo_url,
					});
				}
				// add fill
				else if (e.user_type == 'fill')
				{
					fills.push({
						teetime_id: e.id,
						id: e.id,
						fill_desc: e.fill_desc,
					});
				}
			}

			commit('addTeetimePlayerList', players);
			commit('addTeetimeFillList', fills);
		}
		// otherwise add the current user to the list
		else
		{
			let user = rootState.shell.currentUser;
			let players = [];

			players.push({
				id: user.id,
				user_type: 'member',
				primary_email: user.primary_email,
				class_name: rootState.shell.userClass.name,
				first_name: user.first_name,
				last_name: user.last_name,
				bag_number: user.bag_number,
				member_number: user.member_number,
				cart_id: user.pref_cart_id,
				is_paid: false,
				checked_in: false,
				email_sent: false,
				booked_by: user.id,
				photo_url: user.photo_url,
				user_class_id: user.user_class_id
			});

			commit('addTeetimePlayerList', players);
		}
	},

	async cancelReservation({ commit, dispatch }, params)
	{
		try
		{
			commit('setTeetimesLoading', true);

			await api.post('/reservations/cancel', params);
			dispatch('fetchTeetimes');
		}
		finally
		{
			commit('setTeetimesLoading', false);
		}
	},

	// check to see if this reservation is being made within a block
	/*async fetchReservationBlock({ commit }, id)
	{
		var result = await api.get(`/blocks/${id}`);
		commit('setReservationBlock', { ...result.data.block, ranges: result.data.ranges });
	},*/

	async submitReservation({ state, commit, getters })
	{
		// main reservation details
		var reservation = {
			course_id: state.reservation.courseId,
			block_id: state.reservationBlock ? state.reservationBlock.id : 0,
			date: state.reservation.dateOf,
			time: state.reservation.timeOf,
			hole: state.reservation.hole,
			num_holes: state.reservation.numHoles,
			ignore_restrictions: state.reservation.ignoreRestrictions,
			removed_teetimes: state.removedTeetimes,
			notes: state.reservation.notes,
			ppp: state.teetimePlayers
		};

		// other request parameters
		var params = {};

		if (state.reservation.squeeze)
		{
			params.squeeze = true;
			params.squeeze_id = state.reservation.squeezeId;
		};

		// reservation players
		var players = [];

		// add user players
		for(var p of getters.teetimePlayers)
		{
			players.push({
				teetime_id: p.teetime_id || 0,
				player_id: p.id,
				player_type: 'user',
				cart_id: p.cart_id,
				is_paid: p.is_paid,
				checked_in: p.checked_in,
				sent_email: p.sent_email,
				booked_by: p.booked_by,
				player_class_id: p.user_class_id
			});
		}

		// add fills
		for(var f of getters.teetimeFills)
		{
			players.push({
				teetime_id: f.teetime_id || 0,
				player_id: f.id,
				player_type: 'fill',
				fill_desc: f.fill_desc,
				fill_expires_on: f.expires_on,
				player_class_id: f.user_class_id
			});
		}

		// submit reservation to server
		await api.post('/reservations/submit', { ...params, reservation, players });

		// clear reservation players
		commit('clearReservationPlayers');
	},


	async submitPublicReservation({ state, commit })
	{
		// main reservation details
		var reservation = {
			course_id: state.reservation.courseId,
			block_id: state.reservationBlock ? state.reservationBlock.id : 0,
			date: state.reservation.dateOf,
			time: state.reservation.timeOf,
			hole: state.reservation.hole,
			num_holes: state.reservation.numHoles,
			ignore_restrictions: false,
			booking_notes: state.reservation.bookingNotes,
			num_public_guests: state.reservation.publicGuests,
			public_guest_fills: state.publicPlayerGuests.ids.map(id => state.publicPlayerGuests.guests[id]),
			cart_id: state.reservation.cartPref,
		};

		// submit reservation to server
		await api.post('/reservations/submit_public', { reservation });
	},

	// check to see if this reservation is being made within a block
	async fetchReservationBlock({ commit }, id)
	{
		var result = await api.get(`/blocks/${id}`);
		commit('setReservationBlock', { ...result.data.block, ranges: result.data.ranges });
	},

	// fetch lottery entry data
	async fetchLotteryEntry({ state, commit }, params)
	{
		var result = await api.get(`/lottery_entries/${state.reservationBlock.id}/${params.id}`);
		var players = [], fills = [];

		// clear reservation players
		commit('clearReservationPlayers');

		// delineate entry players into players and fills
		for(var p of result.data.players)
		{
			if (p.user_type == 'member' || p.user_type == 'guest')
				players.push(p);
			else if (p.user_type == 'fill')
				fills.push(p);
		}

		// add players and fills to state
		commit('addTeetimePlayerList', players);
		commit('addTeetimeFillList', fills);

		// commit entry data to state
		commit('setReservationDetails', { ...result.data.entry, is_lottery_entry: true });
	},

	async submitLotteryEntry({ state, getters })
	{
		// reservation players
		var params = { entry: state.reservation, players: []};

		// add user players
		for(var p of getters.teetimePlayers)
		{
			params.players.push({
				id: p.id,
				cart_id: p.cart_id,
				player_type: 'user',
			});
		}

		// add fills
		for(var f of getters.teetimeFills)
		{
			params.players.push({
				id: f.id,
				player_type: 'fill',
				desc: f.fill_desc,
			});
		}

		// submit entry to server
		if (state.reservation.id == 0)
			await api.post(`/lottery_entries/${state.reservationBlock.id}`, params);
		else
			await api.put(`/lottery_entries/${state.reservationBlock.id}/${state.reservation.id}`, params);
	},

	async fetchLotteryEntries({ commit }, blockId)
	{
		var result = await api.get(`/lottery_entries/${blockId}`);
		commit('setLotteryEntries', result.data);
	}
}